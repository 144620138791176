import { Component } from '@angular/core';
import { AppService } from './services/app.service';
import { Router, RoutesRecognized } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AlertController, MenuController, NavController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { filter, pairwise } from 'rxjs/operators';
import {SplashScreen} from '@capacitor/splash-screen';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
// import { Storage } from '@capacitor/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  demoResponse: any;
  bumble_bee: any = 'கல்வி40';
  home: any = 'முகப்பு';
  about_us: any = 'எங்களைப் பற்றி';
  feedback: any = 'கருத்திடவும்';
  rating: any = 'மதிப்பிடுக';
  settings: any = 'அமைப்புகள்';
  logout: any = 'இலாகவுட்';
  value: any;
  previousUrl: any;
  currentUrl: any;

  constructor(public navCtrl: NavController, public service: AppService, public router: Router, private alertCtrl: AlertController,
    public menu: MenuController,private platform: Platform) {
    this.initializeApp();
    //bumble_bee
    if (localStorage.getItem('preferredLanguage') == '1') {
      this.bumble_bee = 'கல்வி40';
    } else if (localStorage.getItem('preferredLanguage') == '2') {
      this.bumble_bee = 'BUMBLE BEE';
    }
    // this.versionCheck();


    //home
    if (localStorage.getItem('preferredLanguage') == '1') {
      this.home = 'முகப்பு';
    } else if (localStorage.getItem('preferredLanguage') == '2') {
      this.home = 'Home';
    }

    //about_us
    if (localStorage.getItem('preferredLanguage') == '1') {
      this.about_us = 'எங்களைப் பற்றி';
    } else if (localStorage.getItem('preferredLanguage') == '2') {
      this.about_us = 'About Us';
    }

    //feedback
    if (localStorage.getItem('preferredLanguage') == '1') {
      this.feedback = 'கருத்திடவும்';
    } else if (localStorage.getItem('preferredLanguage') == '2') {
      this.feedback = 'Feedback';
    }

    //rating
    if (localStorage.getItem('preferredLanguage') == '1') {
      this.rating = 'மதிப்பிடுக';
    } else if (localStorage.getItem('preferredLanguage') == '2') {
      this.rating = 'Ratings';
    }

    //settings
    if (localStorage.getItem('preferredLanguage') == '1') {
      this.settings = 'அமைப்புகள்';
    } else if (localStorage.getItem('preferredLanguage') == '2') {
      this.settings = 'Settings';
    }

    //bumble_bee
    if (localStorage.getItem('preferredLanguage') == '1') {
      this.logout = 'இலாகவுட்';
    } else if (localStorage.getItem('preferredLanguage') == '2') {
      this.logout = 'Logout';
    }

    if (Capacitor.getPlatform() !== 'web') {
      this.initPush();
    }

    SplashScreen.hide({
      fadeOutDuration: 500
    });

    this.router.events
    .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      this.previousUrl = events[0].urlAfterRedirects;
      this.previousUrl = this.previousUrl.slice(1);
      this.currentUrl = events[1].urlAfterRedirects;
      this.currentUrl = this.currentUrl.slice(1);
    });

    var send_authtoken = localStorage.getItem('authtoken');
    if(send_authtoken == null){
      this.navCtrl.navigateForward('login');
    }else{
      this.service.gettoken(send_authtoken).subscribe(response => {
        this.demoResponse = response;
        if (this.demoResponse.status == 'success') {
          this.navCtrl.navigateForward('home');
        } else {
          this.navCtrl.navigateForward('login');
        }
      });
    }
    this.networkCheck();

  }

  ngOnInit() {
    
  }

  ionViewWillEnter(){
    this.networkCheck();
  }

  networkCheck(){
    Network.addListener('networkStatusChange', status => {
      if (!status.connected) {
        this.navCtrl.navigateForward('network-change');
      } else {
        if(this.currentUrl == ''){
          this.navCtrl.navigateForward('home');
        }
        if(this.currentUrl == 'network-change'){
          this.navCtrl.navigateForward(this.previousUrl);
        }else{
          this.navCtrl.navigateForward(this.currentUrl);
        }
      }
    });

    const logCurrentNetworkStatus = async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        this.navCtrl.navigateForward('network-change');
      } else {
        if(this.currentUrl == ''){
          this.navCtrl.navigateForward('home');
        }
        if(this.currentUrl == 'network-change'){
          this.navCtrl.navigateForward(this.previousUrl);
        }else{
          this.navCtrl.navigateForward(this.currentUrl);
        }
      }
    };
  }

  initializeApp() {
    

    //this code is rating popup when the app opens in 10 times

    this.platform.ready().then(() => {

      //this.backgroundMode.enable();

      if (this.platform.is('cordova')) {

        //Subscribe on pause i.e. background
        this.platform.pause.subscribe(() => {
          this.navCtrl.navigateRoot(localStorage.getItem('pageBeforeDisconnect'));
        });

      }


      if (localStorage.getItem('logged_user') == 'true') {
        localStorage.setItem('pageBeforeDisconnect', 'home');
      } else {
        localStorage.setItem('pageBeforeDisconnect', 'user/login');
      }

      localStorage.setItem("mailChimpLocal", "true");
    });
    this.platform.backButton.subscribeWithPriority(5, () => {

      // Catches the active
      let activeView =this.router.url;
      activeView = activeView.substring(1);

      //clicking harware backbutton its dismiss modalpopup seen in current screen and come back to previous screen code 
      if (activeView == "user/login") {
        this.menu.close();
        App.exitApp();
      }

      if (activeView == "home") {
        const alert = this.alertCtrl.create({
          /*  message: 'Do you want to exit from app?', */
          message: 'செயலியை விட்டு வெளியேற விருப்பமா?',
          buttons: [{
            /* text: 'No', */
            text: 'இல்லை',
            role: 'cancel',
            handler: () => { }
          }, {
            /* text: 'Yes', */
            text: 'ஆம்',
            handler: () => {
              this.menu.close();
              App.exitApp();
            }
          }],
          backdropDismiss: false
        }).then(alert => alert.present());
      }

      if (activeView == "register") {
        this.menu.close();
        this.navCtrl.navigateForward('login');
      }
      if (activeView == "about") {
        this.menu.close();
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "feedback") {
        this.menu.close();
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "puzzles") {
        this.menu.close();
        localStorage.removeItem('getCurrentPlayingPuzzlesTime');
        localStorage.removeItem('activePuzzlesAudio');
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "stories") {
        this.menu.close();
        localStorage.removeItem('getCurrentPlayingStoriesTime');
        localStorage.removeItem('activeStoriesAudio');
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "notification") {
        this.menu.close();
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "subjects") {
        this.menu.close();
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "learning") {
        this.menu.close();
        this.navCtrl.navigateForward('subjects');
      }
      if (activeView == "learning/tabs/termone") {
        this.menu.close();
        this.navCtrl.navigateForward('subjects');
      }
      if (activeView == "learning/tabs/termtwo") {
        this.menu.close();
        this.navCtrl.navigateForward('subjects');
      }
      if (activeView == "learning/tabs/termthree") {
        this.menu.close();
        this.navCtrl.navigateForward('subjects');
      }
      if (activeView == "exam-chapters/tabs/exam-termone") {
        this.menu.close();
        this.navCtrl.navigateForward('exams');
      }
      if (activeView == "exam-chapters/tabs/exam-termtwo") {
        this.menu.close();
        this.navCtrl.navigateForward('exams');
      }
      if (activeView == "exam-chapters/tabs/exam-termthree") {
        this.menu.close();
        this.navCtrl.navigateForward('exams');
      }
      if (activeView == "videos") {
        this.menu.close();
        localStorage.removeItem('currentPlayingTime');
        localStorage.removeItem('activeVideo');
        this.navCtrl.navigateForward('learning');
        //removing video last playing time localstorage
      }
      if (activeView == "exams") {
        this.menu.close();
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "exam-chapters") {
        this.menu.close();
        this.navCtrl.navigateForward('exams');
      }
      if (activeView == "tests") {
        this.menu.close();
        this.alertCtrl.dismiss();
        this.navCtrl.navigateForward('exam-chapters');
      }
      if (activeView == "questions") {
        const alert = this.alertCtrl.create({
          /* message: 'Do you want to exit from exam?', */
          message: 'தேர்வை விட்டு வெளியேற விருப்பமா ?',
          buttons: [{
            /* text: 'No', */
            text: 'இல்லை',
            role: 'cancel',
            handler: () => { }
          }, {
            /* text: 'Yes', */
            text: 'ஆம்',
            handler: () => {
              this.menu.close();
              var send_authtoken = localStorage.getItem('authtoken');
              var exam_id = localStorage.getItem('examId');

              this.service.getquestionresponseexam(send_authtoken, localStorage.getItem("exam_question_id"), localStorage.getItem("exam_option_id")).subscribe(response => {
                this.demoResponse = response;
                this.value = null;
              });
              this.service.getfinishquestionexam(send_authtoken, exam_id).subscribe(response => {
                this.demoResponse = response;
                if (this.demoResponse.status == "success") {
                  this.navCtrl.navigateForward('tests');
                } else {
                  console.log("failure");
                }
              });
            }
          }],
          backdropDismiss: false
        }).then(alert => alert.present());
      }
      if (activeView == "temp-result") {
        //this.nav.pop();
        this.menu.close();
        this.navCtrl.navigateForward('tests');
      }
      if (activeView == "result") {
        //this.nav.pop();
        this.menu.close();
        this.navCtrl.navigateForward('temp-result');
      }
      if (activeView == "option-questions") {
        const alert = this.alertCtrl.create({
          /* message: 'Do you want to exit from exam?', */
          message: 'தேர்வை விட்டு வெளியேற விருப்பமா ?',
          buttons: [{
            /* text: 'No', */
            text: 'இல்லை',
            role: 'cancel',
            handler: () => { }
          }, {
            /* text: 'Yes', */
            text: 'ஆம்',
            handler: () => {
              this.menu.close();
              var send_authtoken = localStorage.getItem('authtoken');
              var exam_id = localStorage.getItem('examId');

              this.service.getoptionquestionresponseexam(send_authtoken, localStorage.getItem("exam_question_id"), localStorage.getItem("exam_option_id")).subscribe(response => {
                this.demoResponse = response;
                this.value = null;
              });
              this.service.getfinishoptionquestionexam(send_authtoken, exam_id).subscribe(response => {
                this.demoResponse = response;
                if (this.demoResponse.status == "success") {
                  this.navCtrl.navigateForward('tests');
                } else {
                  console.log("failure");
                }
              });
            }
          }],
          backdropDismiss: false
        }).then(alert => alert.present());
      }
      if (activeView == "option-temp-result") {
        this.menu.close();
        this.navCtrl.navigateForward('tests');
      }
      if (activeView == "option-result") {
        this.menu.close();
        this.navCtrl.navigateForward('option-temp-result');
      }
      if (activeView == "common-topics") {
        this.menu.close();
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "userselection") {
        this.menu.close();
        this.navCtrl.navigateForward('home');
      }
      if (activeView == "user-details-update") {
        this.menu.close();
        this.navCtrl.navigateForward('home');
      }

    });

  }

  
  ionviewwillenter() {
    const dataObj = {
      app_version: "0.0.7"
    }

    this.service.appversion(dataObj).subscribe((data: any) => {
      let response = data.json();
      console.log(response)
      if (!response.latestVersion) {
        this.router.navigate(['version-check']);
      }
    })
  }


  homePage() {
    this.menu.close();
    this.navCtrl.navigateForward('home');
  }

  aboutPage() {
    this.menu.close();
    this.navCtrl.navigateForward('about');
  }

  feedbackPage() {
    this.menu.close();
    this.navCtrl.navigateForward('feedback');
  }


  async logOut() {
    var send_authtoken = localStorage.getItem("authtoken");
    var sendFcmToken = localStorage.getItem('fcmtoken');
    const alert = this.alertCtrl.create({
      /*  message: 'Do you want to exit from app?', */
      message: 'இலாகவுட் செய்ய விருப்பமா?',
      buttons: [{
        /* text: 'No', */
        text: 'இல்லை',
        role: 'cancel',
        handler: () => { }
      }, {
        /* text: 'Yes', */
        text: 'ஆம்',
        handler: () => {
          this.service.logout(send_authtoken, sendFcmToken).subscribe(response => {
            this.demoResponse = response;
            //console.log(this.demoResponse.status);
            if (this.demoResponse.status == 'success') {
              localStorage.removeItem("userId");
              localStorage.removeItem("authtoken");
              localStorage.removeItem("popState");
              localStorage.removeItem('logged_user');
              localStorage.removeItem('DistrictId');
              localStorage.removeItem('general_exam_id');
              localStorage.removeItem('mobileNumber');
              localStorage.removeItem('student_name');
              localStorage.removeItem('userName');
              localStorage.removeItem('standard_id');
              localStorage.removeItem('usercreationId');

              localStorage.removeItem('categoryId');
              localStorage.removeItem('exam_category');
              localStorage.removeItem('complexityId');
              localStorage.removeItem('type_id');
              localStorage.removeItem('chapterId');
              localStorage.removeItem('assesment_type');
              localStorage.removeItem('examId');
              localStorage.removeItem('subjectId');
              // this.menu.close();
              //this.nav.push('Login');
              this.navCtrl.navigateForward('login');
            } else {
              const alert = this.alertCtrl.create({
                /* message: 'Technical error please try after sometimes', */
                message: 'தொழில்நுட்ப பிழை.. தயவுசெய்து இன்னும் சிறிது நேரம் கழித்து  முயற்சிக்கவும்',
                cssClass: "alert-warning",
                buttons: [{
                  /* text: 'Ok', */
                  text: 'சரி',
                  handler: () => {
                  }
                }]
              }).then(alert => alert.present());
            }
          });
        }
      }],
      backdropDismiss: false
    }).then(alert => alert.present());
  }

  ratings() {
    this.menu.close();
    const alert = this.alertCtrl.create({
      header: 'மதிப்பிடுக',
      message: 'எங்கள் மொபைல் ஆப்பை மதிப்பிடலாமே!',
      cssClass: 'alertstar',
      buttons: [
        { text: '1', cssClass: "rating-1 rating-alert", handler: data => { this.resolveRec(1); } },
        { text: '2', cssClass: "rating-2 rating-alert", handler: data => { this.resolveRec(2); } },
        { text: '3', cssClass: "rating-3 rating-alert", handler: data => { this.resolveRec(3); } },
        { text: '4', cssClass: "rating-4 rating-alert", handler: data => { this.resolveRec(4); } },
        { text: '5', cssClass: "rating-5 rating-alert", handler: data => { this.resolveRec(5); } }
      ]
    }).then(alert => alert.present());
  }

  resolveRec(arg0: number) {
    console.log(arg0);
    window.open('https://play.google.com/store/apps/details?id=com.kalvi40.android', '_self');
    //throw new Error("Method not implemented.");
  }

  // Push notification code

  initPush() {
    console.log("push notification init")
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      }
    });

    let that = this;
    PushNotifications.addListener('registration', async (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
      // that.storage.setString('device_id',token.value);
      // await Storage.set({ key: 'fcmtoken', value: token.value });
      localStorage.setItem('fcmtoken', token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: any) => {
        console.log("Push Notification Recieved"+JSON.stringify(notification));
        
        // let url = decodeURIComponent(this.navToTrackingUrl)
        // this.zone.run(() => {
        //   this.eventEmitterService.onGettingNotifications(value);
        // });
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.navCtrl.navigateForward('notification');
      },
    );
  }

}