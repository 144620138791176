import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'subjects',
    loadChildren: () => import('./pages/subjects/subjects.module').then( m => m.SubjectsPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./pages/otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'userselection',
    loadChildren: () => import('./pages/userselection/userselection.module').then( m => m.UserselectionPageModule)
  },
  {
    path: 'learning',
    loadChildren: () => import('./pages/learning/learning.module').then( m => m.LearningPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./pages/videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'puzzles',
    loadChildren: () => import('./pages/puzzles/puzzles.module').then( m => m.PuzzlesPageModule)
  },
  {
    path: 'stories',
    loadChildren: () => import('./pages/stories/stories.module').then( m => m.StoriesPageModule)
  },
  {
    path: 'exams',
    loadChildren: () => import('./pages/exams/exams.module').then( m => m.ExamsPageModule)
  },
  {
    path: 'exam-chapters',
    loadChildren: () => import('./pages/exam-chapters/exam-chapters.module').then( m => m.ExamChaptersPageModule)
  },
  {
    path: 'tests',
    loadChildren: () => import('./pages/tests/tests.module').then( m => m.TestsPageModule)
  },
  {
    path: 'user-details-update',
    loadChildren: () => import('./pages/user-details-update/user-details-update.module').then( m => m.UserDetailsUpdatePageModule)
  },
  {
    path: 'version-update',
    loadChildren: () => import('./pages/version-update/version-update.module').then( m => m.VersionUpdatePageModule)
  },
  {
    path: 'option-questions',
    loadChildren: () => import('./pages/option-questions/option-questions.module').then( m => m.OptionQuestionsPageModule)
  },
  {
    path: 'questions',
    loadChildren: () => import('./pages/questions/questions.module').then( m => m.QuestionsPageModule)
  },
  {
    path: 'option-temp-result',
    loadChildren: () => import('./pages/option-temp-result/option-temp-result.module').then( m => m.OptionTempResultPageModule)
  },
  {
    path: 'temp-result',
    loadChildren: () => import('./pages/temp-result/temp-result.module').then( m => m.TempResultPageModule)
  },
  {
    path: 'option-result',
    loadChildren: () => import('./pages/option-result/option-result.module').then( m => m.OptionResultPageModule)
  },
  {
    path: 'result',
    loadChildren: () => import('./pages/result/result.module').then( m => m.ResultPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'common-topics',
    loadChildren: () => import('./pages/common-topics/common-topics.module').then( m => m.CommonTopicsPageModule)
  },
  {
    path: 'network-change',
    loadChildren: () => import('./pages/network-change/network-change.module').then( m => m.NetworkChangePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
