import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public data:any;
  public baseUrl:any;
  constructor(public http : HttpClient) {
    this.baseUrl = environment.databaseURL;
   }

  appversion(dataObj: any){
    let params = {'app_version':dataObj.app_version };
    return this.http.get(environment.databaseURL + '/check/app/version',  { params })    
  }

  getfinisheventexam(authtoken:any, general_exam_id:any){
    let headers =  {'authtoken':authtoken};
      let params: URLSearchParams = new URLSearchParams();
      params.set('general_exam_id', general_exam_id);
      return this.http.post(this.baseUrl+'/finish/general/exam/'+general_exam_id+'/end_time/insert', this.data, {headers: headers});
  }

  getquestionresponseexam(authtoken:any, questionId:any, value:any){
    let headers =  {'authtoken':authtoken};
      let params: any = {};
      params['correct_option']= value;
      return this.http.post(this.baseUrl+'/exam/response/question/'+questionId, params, {headers: headers});
  }

  gettoken(send_authtoken:any): Observable<any>{
    let headersData = new HttpHeaders();
      let data:any = {
        "authtoken": send_authtoken
      };
      headersData = data;
      return this.http.post(this.baseUrl+'/token/check', this.data, {headers:headersData});
  }

  getfinishquestionexam(authtoken:any, exam_id:any){
    let headers =  {'authtoken':authtoken};
      let params: URLSearchParams = new URLSearchParams();
      params.set('exam_id', exam_id);
      return this.http.post(this.baseUrl+'/finish/exam/'+exam_id+'/end_time/insert', this.data, {headers: headers});
  }

  getoptionquestionresponseexam(authtoken:any, questionId:any, value:any){
    let headers =  {'authtoken':authtoken};
      let params: any = {};
      params['correct_option']= value;
      return this.http.post(this.baseUrl+'/exam/yes/no/response/question/'+questionId, params, {headers: headers});
  }

  getfinishoptionquestionexam(authtoken:any, exam_id:any){
    let headers =  {'authtoken':authtoken};
      let params: URLSearchParams = new URLSearchParams();
      params.set('exam_id', exam_id);
      return this.http.post(this.baseUrl+'/finish/yes/no/exam/'+exam_id+'/end_time/insert', this.data, {headers: headers});
  }

  geteventresponseexam(authtoken:any, questionId:any, value:any){
    let headers =  {'authtoken':authtoken};
      let params: any = {};
      params['correct_option']= value;
      return this.http.post(this.baseUrl+'/general/exam/response/question/'+questionId, params, {headers: headers})
  }


  logout(authtoken:any, sendFcmToken:any){
    let headers =  {'authtoken':authtoken};
      console.log(localStorage.getItem('fcmtoken'));
      let params: any = {};
      return this.http.post(this.baseUrl+'/user/logout/fcmToken/'+sendFcmToken, params, {headers: headers});
  }
}
