import { Component, Input, OnInit } from '@angular/core';
import { NavController, MenuController, PopoverController } from '@ionic/angular';
import { UserRegistrationService } from 'src/app/services/user-registration.service';
import { Observable } from 'rxjs';
// import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.scss'],
  // imports: [CommonModule],
})
export class UserCreationComponent implements OnInit {

  ngOnInit() {}
  @Input() data: any;

  public firstname: string = '';
  public firstnameerror: boolean = false;
  public firstnameErrorMsg:any;

  public language: string;
  public languageerror: boolean = false;
  public languageErrorMsg:any;

  public standard: string;
  public standarderror: boolean = false;
  public standardErrorMsg:any;

  public languageCombo:any;
  public districtCombo:any;
  public locationData:any;
  public schoolTypeCombo:any;
  public standardCombo:any;
  public blockCombo:any;
  
  public location_id = "";
  public location_input = "";
  public child_hidden = true;

  public first_name:any;
  public std:any;

  demoResponse : any;
  data2: Observable<any>;
  register_acc:any;
  log_in:any;
  field_empty:any;

  searchTerm:any="";
  allItems:any;

  constructor(public service: UserRegistrationService, public navCtrl: NavController, public menu: MenuController, public popoverCtrl: PopoverController) {
    this.service.getdropdown().subscribe(response  => {
      this.demoResponse = response;
      service.customLoad(this.demoResponse).subscribe(snapshot => {
      this.data = snapshot;
      this.languageCombo = this.data.languageCombo;
      this.districtCombo = this.data.districtCombo;
      this.locationData = this.data.locationData;
      this.schoolTypeCombo = this.data.schoolTypeCombo;
      this.standardCombo = this.data.standardCombo;

      this.blockCombo = Array({'block_id':'SELECT', 'block_name':'Select'});
      this.allItems =   this.locationData;
      });
    });

    localStorage.setItem('preferredLanguage','1');

    //sign_up
    if(localStorage.getItem('preferredLanguage')=='1'){
      this.register_acc = 'பதிவு';
    } else if(localStorage.getItem('preferredLanguage')=='2') {
      this.register_acc = 'Register';
    }

    //first_name
    if(localStorage.getItem('preferredLanguage')=='1'){
      //this.first_name = 'முதல் பெயர்';
      this.first_name = 'பெயர்';
    } else if(localStorage.getItem('preferredLanguage')=='2') {
      //this.first_name = 'First Name';
      this.first_name = 'Full Name';
    }
    
    //std
    if(localStorage.getItem('preferredLanguage')=='1'){
      this.std = 'வகுப்பு';
    } else if(localStorage.getItem('preferredLanguage')=='2') {
      this.std = 'Standard';
    }

  }

  validateFirstName(){
    //var nameFormat = /^[a-zA-Z ]+$/;
    this.firstnameerror=false;
    var allowedTamil = "அஆஇஈஉஊஎஏஐஒஓஔக்ககாகிகீகுகூகெகேகைகொகோகௌங்ஙஙாஙிஙீஙுஙூஙெஙேஙைஙொஙோஙௌச்சசாசிசீசுசூசெசேசைசொசோசௌஞ்ஞஞாஞிஞீஞுஞூஞெஞேஞைஞொஞோஞௌட்டடாடிடீடுடூடெடேடைடொடோடௌண்ணணாணிணீணுணூணெணேணைணொணோணௌத்ததாதிதீதுதூதெதேதைதொதோதௌந்நநாநிநீநுநூநெநேநைநொநோநௌப்பபாபிபீபுபூபெபேபைபொபோபௌம்மமாமிமீமுமூமெமேமைமொமோமௌய்யயாயியீயுயூயெயேயையொயோயௌர்ரராரிரீருரூரெரேரைரொரோரௌல்லலாலிலீலுலூலெலேலைலொலோலௌவ்வவாவிவீவுவூவெவேவைவொவோவௌழ்ழழாழிழீழுழூழெழேழைழொழோழௌள்ளளாளிளீளுளூளெளேளைளொளோளௌற்றறாறிறீறுறூறெறேறைறொறோறௌன்னனானினீனுனூனெனேனைனொனோனௌ";

    var allowedTamilGrantham = "ஶ்ஶஶாஶிஶீஶுஶூஶெஶேஶைஶொஶோஶௌஜ்ஜஜாஜிஜீஜுஜூஜெஜேஜைஜொஜோஜௌஷ்ஷஷாஷிஷீஷுஷூஷெஷேஷைஷொஷோஷௌஸ்ஸஸாஸிஸீஸுஸூஸெஸேஸைஸொஸோஸௌஹ்ஹஹாஹிஹீஹுஹூஹெஹேஹைஹொஹோஹௌக்ஷ்க்ஷக்ஷாக்ஷிக்ஷீக்ஷுக்ஷூக்ஷெக்ஷேக்ஷைக்ஷொக்ஷோக்ஷௌ";

    var allowedEnglish = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    var allowedDot = ".";
    var allowedComma = ",";
    var allowedHiffen = "-";
    var allowedUnderscore = "_";

   // var allowedNumbers = "0123456789 ";
    this.firstname = this.firstname.trim();
    var allowedLetters = " "+allowedEnglish+allowedTamil+allowedTamilGrantham+allowedDot+allowedComma+allowedHiffen+allowedUnderscore;
    for (let index = 0; index < this.firstname.length; index++) {
      if(allowedLetters.indexOf(this.firstname[index]) === -1){
        this.firstnameerror=true;    
        /* this.firstnameErrorMsg = "Please fill the first name text field"; */
        this.firstnameErrorMsg = "உங்கள் பெயரை தமிழ் / ஆங்கில எழுத்துக்களில் மட்டும் உள்ளிடவும்.";
      }
    }
    if(this.firstname==""){
      this.firstnameerror=true;
      /* this.firstnameErrorMsg = "Please fill the first name text field"; */
      this.firstnameErrorMsg = "உங்களின் பெயரை நிரப்பவும்";
    } else if(this.firstname==null){
      this.firstnameerror=true;
      /* this.firstnameErrorMsg = "Please fill the first name text field"; */
      this.firstnameErrorMsg = "உங்களின் பெயரை நிரப்பவும்";
    } else if(this.firstname.length==0){
        this.firstnameerror=true;
        /* this.firstnameErrorMsg = "First Name should not be left empty"; */
        this.firstnameErrorMsg = "பெயரை காலியாக விடக்கூடாது";
    } else if(this.firstname.length<3){
        this.firstnameerror=true;
        /* this.firstnameErrorMsg = "First Name should be greater than 2 letters"; */
        this.firstnameErrorMsg = "பெயர் 2 எழுத்துக்களை விட அதிகமாக இருக்க வேண்டும்";
    } else if(this.firstname.length>30){
      this.firstnameerror=true;
      /* this.firstnameErrorMsg = "First Name should be less than 30 letters"; */
      this.firstnameErrorMsg = "பெயர் 30 எழுத்துகளுக்குள் இருக்க வேண்டும்";
    } 
  }

  validateStandard(){
    if(this.standard==null){
      this.standarderror=true;
      /* this.standardErrorMsg = "Please select the standard"; */
      this.standardErrorMsg = "உங்கள் வகுப்பை தேர்ந்தெடுக்கவும்";
    } else {
      this.standarderror=false;
    }
  }

  checkValidation(fieldName:any){
    if(fieldName=='firstname'){
        this.validateFirstName();
    } else if(fieldName=='standard'){
        this.validateStandard();
    } 
  }

  registrationValidation(){
      this.validateFirstName();
      this.validateStandard();
  }

  getDistrictid(id:any){
    let DistrictId:any = id;
    localStorage.setItem('DistrictId', DistrictId);
    var district_id = localStorage.getItem('DistrictId');
    this.service.getblockdropdown(district_id).subscribe(response  => {
      this.demoResponse = response;
      this.service.customLoad(this.demoResponse).subscribe(snapshot => {
      this.data = snapshot;
      //console.log(this.data);
      this.blockCombo = this.data.blockCombo;
      });
    });
  }

  register(){
    this.registrationValidation();
    var registration_authtoken = localStorage.getItem('authtoken');
    var mobile_number = localStorage.getItem('mobileNumber');
    var register_userid = localStorage.getItem('usercreationId');
    if(this.firstnameerror == false && this.standarderror == false){
        this.service.getregister(this.firstname, this.standard, registration_authtoken, mobile_number, register_userid);
        this.navCtrl.navigateForward('home');
        this.popoverCtrl.dismiss();
    } else {
      return false;
      this.popoverCtrl.dismiss();
    }
  }
  
  selectLocation(item:any){
    this.location_id = item.location_id;
    this.location_input = item.location_name;
    this.child_hidden = true;
  }

  getItems(ev: any) {
    this.location_id = '';
    //console.log(ev);
    this.child_hidden = false;
    // Reset items back to all of the items
    this.allItems = this.locationData;
    // set val to the value of the searchbar
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      this.allItems = this.allItems.filter((item: LocationData) => {
        return (item.location_name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }

}

export interface LocationData {
  location_name : string; 
}
