import { Injectable } from '@angular/core';
import { AlertController, NavController, ModalController } from '@ionic/angular';
// import { Http, URLSearchParams  } from '@angular/http';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserRegistrationService {

    public baseUrl:any;
    public data:any;
    public authtoken:any;
    public firstname: string;
    public standard: string;

    constructor(public http: HttpClient, public modalCtrl: ModalController, private alertCtrl: AlertController, public navCtrl: NavController) {
        this.baseUrl = environment.databaseURL;
        this.authtoken = localStorage.getItem('authtoken');
    }

    getdropdown(){
        return this.http.get(this.baseUrl+'/admin/registration/get')
    }
 
    getblockdropdown(district_id:any){
        return this.http.get(this.baseUrl+'/district/'+district_id+'/block/get')
    }

    getregister(firstname:any, standard:any, registration_authtoken:any, mobile_number:any, register_userid:any){
        let params: any = {};
        if(typeof registration_authtoken !== undefined) {
            params['student_name']= firstname;
            params['standard_id']= standard;
            params['authtoken']= registration_authtoken;
            params['mobile_number']= mobile_number;
            this.data = this.http.post(this.baseUrl+'/registration/add/user/'+register_userid+'/insert', params)
        } else {
            params['student_name']= firstname;
            params['standard_id']= standard;
            params['authtoken']= registration_authtoken;
            this.data = this.http.post(this.baseUrl+'/registration/add/user/'+register_userid+'/insert', params)
        }
        this.data.subscribe(data=>{
            data = data;
            if(data.status == "success"){
                // var userId =  data.user_id;
                // localStorage.setItem('userId',userId);
                let alert = this.alertCtrl.create({
                    /* message: "Registration Successful", */
                    message: "பதிவு செய்யப்பட்டது",
                    cssClass : "alert-warning",
                    buttons: [
                    {
                        /* text: 'Ok', */
                        text: 'சரி',
                        handler: () => {
                        //this.navCtrl.push('UserSelection');
                        this.navCtrl.navigateForward('userselection');
                        // this.modalCtrl.dismiss();
                        //this.navCtrl.push('Login');
                        //console.log('Ok clicked');
                        }
                    }
                    ],
                    backdropDismiss: false
                }).then(alert=> alert.present());
            } else {
                let alert =  this.alertCtrl.create({
                /* message: "This user is already exists, please try registering new user", */
                message: "இந்த பயனர் ஏற்கனவே பதிவு செய்யப்பட்டுள்ளார் , தயவுசெய்து புதிய பயனரைப் பதிவுசெய்யவும்",
                cssClass : "alert-warning",
                buttons: [
                {
                    /* text: 'OK', */
                        text: 'சரி',
                    handler: () => {
                    //console.log('Ok clicked');
                    }
                }
                ],
                backdropDismiss: false
            }).then(alert=> alert.present());
        }
        //console.log(data);
        });
    
}

    private catchError(error: HttpErrorResponse){
        //console.log(error.status);
        var samplearray = [];
        if(error.status==301 || error.status==302 || error.status==304 || error.status==403 || error.status==404 || error.status==405 || error.status==419 || error.status==500 || error.status==502 || error.status==503 || error.status==504) {
            //alert('Technical error please try after sometimes');
            alert('தொழில்நுட்ப பிழை.. தயவுசெய்து இன்னும் சிறிது நேரம் கழித்து  முயற்சிக்கவும்');
            /* const alert = this.alertCtrl.create({
                //message: "Invalid OTP, Please Enter Valid OTP",
                message: "தொழில்நுட்ப பிழை.. தயவுசெய்து இன்னும் சிறிது நேரம் கழித்து முயற்சிக்கவும்",
                cssClass : "alert-warning",
                buttons: [
                  {
                    //text: 'OK',
                    text: 'சரி',
                    handler: () => {
                      //console.log('Ok clicked');
                    }
                  }
                ],
                enableBackdropDismiss: false
              });
              alert.present(); */
            samplearray['status']='no';
            //this.load();
        } else if (error.status==401){
            //var samplearray = [];
            //alert('Unauthorized user access');
            samplearray['status']='no';
            this.load();
        } 
       return samplearray;
    }

    private logResponse(res: any){
        //console.log(res);  
        var localReturnObject = {};
        localReturnObject['districtCombo']=res.districtCombo;
        localReturnObject['languageCombo']=res.languageCombo;
        localReturnObject['locationData']=res.locationData;
        localReturnObject['schoolTypeCombo']=res.schoolTypeCombo;
        localReturnObject['standardCombo']=res.standardCombo;
        localReturnObject['blockCombo']=res.blockCombo;
        return localReturnObject;
    }

    load(): Observable<any> {
        // if (AppSettings.IS_FIREBASE_ENABLED) {
        //     return new Observable(observer => {
        //         this.af
        //             .object('home')
        //             .valueChanges()
        //             .subscribe(snapshot => {
        //                 observer.next(snapshot);
        //                 observer.complete();
        //             }, err => {
        //                 observer.error([]);
        //                 observer.complete();
        //             });
        //     });
        // } else {
            return new Observable(observer => {
                observer.next(this.getdropdown());
                observer.complete();
            });
        // }
    }

    customLoad(dropdown : any): Observable<any> {
        // if (AppSettings.IS_FIREBASE_ENABLED) {
        //     return new Observable(observer => {
        //         this.af
        //             .object('home')
        //             .valueChanges()
        //             .subscribe(snapshot => {
        //                 observer.next(snapshot);
        //                 observer.complete();
        //             }, err => {
        //                 observer.error([]);
        //                 observer.complete();
        //             });
        //     });
        // } else {
            return new Observable(observer => {
                observer.next(this.logResponse(dropdown));
                observer.complete();
            });
        // }
    }

}

